import React from "react"
import { ReactDOM } from "react-dom/client"
import MainLayout from "../components/Layout/MainLayout"

const Landing = () => {
  return (
    <MainLayout/>
  )
}

export default Landing