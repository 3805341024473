import { styled } from "@mui/material";
import React from "react";

export const CustomNavbar = styled("nav")(({theme})=>({
    display:"flex",
    justifyContent:"space-between",
    padding: "0.5rem 1rem",
    alignItems:"center",
    maxWidth: "70%",
    position:"sticky",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    background: "transparent",
    backdropFilter: "blur(10px)",
    margin:"0 auto",
    borderRadius:"24px",
    top:"0px",
    zIndex: "100",
}));