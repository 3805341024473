import { createBrowserRouter } from "react-router-dom";
import PATH from "./params";
import App from "../App";
import Landing from "../pages/Landing";


export const appRouter = createBrowserRouter([
    {
        path:PATH.HOME,
        element: <App/>,
        children:
        [
            {
                path: PATH.HOME,
                element: <Landing/>
            },
            {
                path: PATH.CONTACT,
                element: <><div>Hi Contact page</div></>
            }
        ],
    }
])

export default appRouter