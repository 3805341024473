import React from 'react'
import { PageLayout } from './MainLayoutStyle'
import HeroSection from '../../pages/HeroSection'
import InfoSection from '../../pages/InfoSection'
import Footer from '../../pages/Footer'


const MainLayout = () => {
  return (
    <PageLayout>
        <HeroSection/>
        <InfoSection/>
        <Footer/>
    </PageLayout>
  )
}

export default MainLayout