import { Box, Button, Typography, styled } from '@mui/material'
import React from 'react'
import heroSectionImg from "../../public/assets/images/hero_section.jpg"

const CustomHeroSection = styled("section")(({theme})=>({
   display: "grid",
   gridTemplateColumns: "1fr 1fr",
   gap:"1rem",
//    placeItems: "center",
   [theme.breakpoints.down("md")]:{
       gridTemplateColumns: "1fr",
   },
}))

const HeroSection = () => {
  return (
    <CustomHeroSection>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem"}}>

                <Typography  sx={{ lineHeight:"1", fontSize: "3.75rem", color: (theme)=>theme.palette.primary.main}}>Connecting Quality and Safety, Wire by Wire.</Typography>
                <Typography variant='h3' color="primary" sx={{ letterSpacing: "2px"}}>
                LAHARA ELECTRICALS PRIVATE LIMITED is one of the pioneer proprietorship company engaged in trading business for LT/HT/EHV Cables, House Wires & Optical Fiber Cable and its accessories upto 220kV ratings.</Typography>
                
                    <Box>
                        <Button variant="contained" color="secondary" >Contact us</Button>
                    </Box>
        </Box>
                    <div>
                        <img src={heroSectionImg} alt="hero_section_img" style={{ width: "100%", objectFit:"cover", borderRadius: "8px" }}/>
                    </div>

    </CustomHeroSection>
  )
}

export default HeroSection