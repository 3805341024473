import { Box, Typography, styled } from '@mui/material'
import React from 'react'
import mailsIcon from "../../public/assets/icons/mails.png"
import insight from "../../public/assets/icons/insight.png"
import solution from "../../public/assets/icons/instantSolutions.png"
import connection from "../../public/assets/icons/customerConnection.png"
import webIntegration from "../../public/assets/icons/webIntegration.png"
import teamManagement from "../../public/assets/icons/teamManagement.png"

const CustomInfoSection = styled("section")(({theme})=>({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap:"4rem",
 }))

 const CustomCards = styled("section")(({theme})=>({
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap:"1rem",
    rowGap: "3rem",
    [theme.breakpoints.down("md")]:{
        gridTemplateColumns: "1fr 1fr",
    },
    [theme.breakpoints.down("sm")]:{
        gridTemplateColumns: "1fr",
    }
 }))

 const cardData = [
    {label: "Streamline Team Communication", description: "Streamline communication within your team using our shared team inboxes. Enhance collaboration and keep everyone on the same page effortlessly.",
    icon: mailsIcon},
    {label: "Gain Insights with Analytics", description: "Gain valuable insights and data analytics for your team through our analytics dashboard. Make informed decisions and track your progress effectively.",
    icon: insight},
    {label: "Provide Instant Solutions", description: "Deliver instant answers to your customers or team members with our shared team inboxes. Ensure quick responses and efficient problem-solving.",
    icon: solution},
    {label: "Enhance Customer Connections", description: "Connect with your customers seamlessly using our shared team inboxes. Improve customer interactions and build stronger relationships.",
    icon: connection},
    {label: "Seamless Web Integration", description: "Integrate our shared team inboxes seamlessly with your website. Enhance the user experience and provide efficient communication channels.",
    icon: webIntegration},
    {label: "Effortless Team Management", description: "Manage your team effortlessly with our shared team inboxes. Simplify team reporting and ensure smooth operations.",
    icon: teamManagement},
 ]

 const CardStyle = styled("div")(({theme})=>({
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    placeItems:"center",
    boxSizing: "border-box",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    background: "#fff",
    padding: "1rem",
    cursor:"pointer",
    transition: "all 0.3s ease",
    ":hover":{
        transform: "translateY(-4px)",
        transition: "all 0.3s ease",
        boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
    }
 }))

const InfoSection = () => {
  return (
    <>
        <CustomInfoSection>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem", placeItems:"center"}}>
                <Typography variant="h4" color="primary" sx={{ letterSpacing: "1px", fontWeight: "bold"}}>Best in the industry</Typography>
                <Box sx={{ maxWidth: "80%"}}>
                <Typography variant="body1"  sx={{textAlign:"center", letterSpacing: "2px", color:(theme)=>theme.palette.primary.light}}>
                Lahara Electricals is a leading name in the electrical industry, specializing in the distribution of high-quality electrical wires and metals. With a strong commitment to excellence and safety, we provide a comprehensive range of electrical products that cater to various industries and applications. Our extensive product line includes a wide selection of electrical wires and metal components, ensuring that our customers have access to the finest materials for their projects.
                </Typography>
                </Box>
            </Box>
            <CustomCards>
                {cardData.map((card, index)=>(
                    <CardStyle key={index}>
                        <img src={card.icon} alt="mails_icon" style={{ width: "50px", height: "50px", objectFit: "cover", borderRadius:"24px"}}/>
                        <Typography variant="h5" color="primary" sx={{textAlign:"center", letterSpacing: "1px", fontWeight: "bold"}}>{card.label}</Typography>
                        <Typography variant="body1"  sx={{textAlign:"center", letterSpacing: "2px", color:(theme)=>theme.palette.primary.light}}>{card.description}</Typography>
                    </CardStyle>
                ))}
            </CustomCards>    
        </CustomInfoSection>
    </>
  )
}

export default InfoSection