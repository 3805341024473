import React, { StrictMode } from "react";
import ReactDOM  from "react-dom/client";
import App from "./src/App";
import { RouterProvider } from "react-router-dom";
import appRouter from "./src/router/router";
import { ThemeProvider } from "@emotion/react";
import { defaultTheme } from "./src/styles/muiTheme";


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <>
        <StrictMode>
        <ThemeProvider theme={defaultTheme}>
            <RouterProvider router={appRouter}>
                <App/>
            </RouterProvider>    
        </ThemeProvider>    
        </StrictMode>
    </>
)
