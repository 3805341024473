import { createTheme } from "@mui/material";

const paletteColors = {
  primary: {
    main: "#1B2559",
    light: "#707EAE",
    dark:""
  },
  secondary: {
    main: "#DC2626",
    light: "",
  },
  aqua:{
    main: "#15B79E"
  },
  white:{
    main: "#fff"
  },
  black:{
    main: "#000"
  },
  gray:{
    main: "#9da4ae"
  }
};

export const defaultTheme = createTheme({
  typography: {
    fontFamily: [
      "-apple-system",
      "sans-serif",
      "Cinzel",
    ].join(","),
  },
  palette: { ...paletteColors,  },
  components: {
    MuiButton: {
      defaultProps :{
        disableElevation: true,
        variant: "contained",
      },
      styleOverrides: {
        root: {
          borderRadius: "48px",
          height: "48px",
          letterSpacing: "0.45px",
          padding: "0 1.25rem",
        }
      },
      variants: [
        {
          props: {
            size: "small"
          },
          style:{
            height: "32px",
            minWidth: "auto",
            fontSize: "12px",
            fontFamily: "sans-serif",
            color: (theme)=> theme.palette.primary.main,
          }
        },
        {
          props:{
            variant: "contained",
            color: "primary"
          },
          style:{
            border:"1px solid transparent",
            ":hover":{
              border: "1px solid transparent",
              color: "#fff",
              backgroundColor: (theme)=> theme.palette.secondary.main,
            },
            fontFamily: "sans-serif",
            fontWeight:"bold",
            letterSpacing: "2px",
          }
        },
        {
          props:{
            variant: "contained",
            color: "error"
          },
          style:{
            border:"1px solid transparent",
            ":hover":{
              border: "1px solid transparent",
              color: "#fff",
              backgroundColor: (theme)=> theme.palette.error.dark,
            },
            fontFamily: "sans-serif",
            fontWeight:"bold",
            letterSpacing: "2px",
          }
        },
        {
          props:{
            variant: "contained",
            color: "warning"
          },
          style:{
            border:"1px solid transparent",
            ":hover":{
              border: "1px solid transparent",
              color: "#fff",
              backgroundColor: (theme)=> theme.palette.warning.dark,
            },
            fontFamily: "sans-serif",
            fontWeight:"bold",
            letterSpacing: "2px",
          }
        }
      ]
    }
  }
});

defaultTheme.typography.h1 = {
  fontSize: "2rem",
  fontWeight: 300,
  lineHeight: "30px",
  fontFamily: "sans-serif",
};

defaultTheme.typography.h2 = {
  fontSize: "20px",
  fontWeight: 400,
  lineHeight: "30px",
};

defaultTheme.typography.h3 ={
  fontSize: "18px",
  fontWeight: 400,
  lineHeight: "30px",
}


defaultTheme.typography.body1 = {
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
};

defaultTheme.typography.body2 = {
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
};