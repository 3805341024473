import { styled } from "@mui/material";

export const PageLayout = styled("div")(({theme})=>({
    display: "flex",
    flexDirection: "column",
    maxWidth:"70%",
    position: "relative",
    gap:"2rem",
    top: "5%",
    margin: "0 auto",
    padding: "0 20px",
    [theme.breakpoints.down("md")]:{
        maxWidth:"90%",
        padding: "0 10px",
        margin: "0 auto",
    },
}))