import React from 'react'
import PATH from '../router/params'
import { CustomNavbar } from './NavbarStyle'
import { Link, List, ListItem } from '@mui/material'
import companyLogo from "../../public/assets/images/logo1.png"

const Navbar = () => {

    const navbarData = [
         {  title: "Home",
            path: PATH.HOME,
         },
         {  title: "About Us",
         path: PATH.CONTACT,
         },
         {  title: "Product",
         path: PATH.CONTACT,
         },
         {  title: "Contact",
         path: PATH.CONTACT,
         },
    ]

  return (
        <CustomNavbar>
            <div style={{ width:"96px"}}>
                <Link href="/"> <img src={companyLogo} alt="logo_img" style={{ width: "96px", objectFit:"cover" }}></img></Link>
            </div> 
                <List sx={{ display:"flex", alignItems:"center", flexBasis: "50%", justifyContent:"space-around"   }}>
                    {navbarData.map((menuItem)=>(
                        <ListItem key={menuItem.title} sx={{ cursor:"pointer", transition: "all 0.3s ease-in-out", color: "primary.main", fontWeight:"600", ":hover":{
                            color: "secondary.main", transform:"scale(1.1)", transition: "all 0.3s ease-in-out"
                            }}}><Link href="#" sx={{ textDecoration:"none" }} >{menuItem.title}</Link></ListItem>
                    ))}
                </List>
        </CustomNavbar>
  )
}

export default Navbar