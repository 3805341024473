import React from 'react'
import facebookicon from "../../public/assets/icons/facebook.svg"
import twittericon from "../../public/assets/icons/twitter.svg"
import instagramicon from "../../public/assets/icons/instagram.svg"
import logoImg from "../../public/assets/images/logo1.png"
import { Box, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

const Footer = () => {
const socialIcons = [
    { icon: facebookicon, alt: "Facebook", width: "24px" },
    { icon: twittericon, alt: "Twitter", width: "24px" },
    { icon: instagramicon, alt: "Instagram", width: "24px" },
];

return (
    <Box component="footer" sx={{ display: "flex", gap:"1rem", placeItems:"center", flexDirection: "column", position:"relative",  minHeight: "120px"}}>
        <img src={logoImg} alt="logo_img" style={{ width: "96px", objectFit:"cover" }}></img>
        <Box sx={{ display: "flex", gap: "1rem", justifyContent:"center"}}>
            {socialIcons.map((socialIcon, index) => (
                <Link href="#" target='_blank' key={index} rel="noreferrer" sx={{ cursor: "pointer"}}>
                    <img src={socialIcon.icon} alt={socialIcon.alt} style={{ width: socialIcon.width, objectFit:"cover",
                    cursor: "pointer"}}></img>
                </Link>
            ))}
        </Box>
        <Typography variant='body1' color="primary">© 2023 Lahara Electricals. All rights reserved.</Typography>
    </Box>
);
}

export default Footer